<template>
	<div id="logon">
		<common-header></common-header>
		<div class="login-box">
			<h1>欢迎使用轻重缓急工具</h1>
			<div class="login-input">
				<el-form
					:model="Register"
					ref="RegisterForm"
					label-width="0"
					class="RegisterForm"
				>
					<!--                    手机号-->
					<el-form-item prop="email" class="email-box">
						<div>
							<i class="iconfont iconuser"></i>
						</div>
						<el-input
							v-model="Register.phone"
							placeholder="手机号"
							onkeyup="value=value.replace(/[^\d]/g,'')"
							maxlength="11"
						></el-input>
					</el-form-item>
					<!--                    邮箱验证码-->
					<el-form-item prop="test" class="code">
						<div>
							<i class="iconfont iconVerificationCode1"></i>
						</div>
						<el-input
							v-model="Register.safeCode"
							placeholder="验证码"
						></el-input>
						<el-button
							type="button"
							@click="sendcode"
							:disabled="disabled"
							v-if="!disabled"
							class="send"
							>获取验证码</el-button
						>
						<el-button
							v-else
							type="button"
							@click="sendcode"
							:disabled="disabled"
							class="send ncolor"
							>{{ btntxt }}</el-button
						>
					</el-form-item>
					<!--                    密码-->
					<el-form-item prop="paw" class="paw-box">
						<div>
							<i class="iconfont iconpassword"></i>
						</div>
						<el-input
							type="password"
							v-model="Register.password"
							placeholder="密码"
						></el-input>
					</el-form-item>
					<el-form-item prop="paw" class="paw-box">
						<div>
							<i class="iconfont iconpassword"></i>
						</div>
						<el-input
							type="password"
							v-model="Register.againPassword"
							placeholder="再次输入密码"
						></el-input>
					</el-form-item>
					<el-form-item>
						<el-button
							type="primary"
							@click="submitForm"
							class="sign-in-btn"
							>注册</el-button
						>
					</el-form-item>
				</el-form>
			</div>
		</div>
		<div class="copyright">
			©2020-2021轻重缓急All Rights Reserved
			<a href="https://beian.miit.gov.cn/" target="_blank"
				>粤ICP备15072947号-6
			</a>
		</div>
	</div>
</template>

<script>
import CommonHeader from '@/components/CommonHeader'

export default {
	name: 'Logon',
	components: { CommonHeader },
	data() {
		return {
			Register: {
				phone: '', // 手机号
				safeCode: '', // 验证码
				password: '', // 密码
				againPassword: '', // 再次输入密码
			},
			disabled: false,
			textedTime: 0, // 等待60s
			btntxt: '重新发送',
		}
	},
	methods: {
		// 注册
		submitForm() {
			this.$http({
				url: this.api.register,
				data: {
					phone: this.Register.phone,
					password: this.Register.password,
					repassword: this.Register.againPassword,
					code: this.Register.safeCode,
				},
			}).then((res) => {
				console.log('注册', res)
				this.$message.success({
					message: res.msg,
					center: true,
				})
				if (res.code === 0) {
					setTimeout(() => {
						this.$router.push('login')
					}, 2000)
				}
			})
		},
		// 发送验证码
		sendcode() {
			// 发送成功
			this.$http({
				url: this.api.get_phone_code,
				data: {
					phone: this.Register.phone,
				},
			})
				.then((res) => {
					if (res.code === 1) {
						this.$message.warning({
							message: res.msg,
							center: true,
						})
					} else if (res.code === 0) {
						this.$message.success({
							message: res.msg,
							center: true,
						})
						this.textedTime = 60
						this.disabled = true
						this.timer()
					}
					console.log('获取验证码', res)
				})
				.catch((err) => {
					console.log(err)
				})
		},
		// 60S倒计时
		timer() {
			if (this.textedTime > 0) {
				this.textedTime--
				this.btntxt = this.textedTime + 's后重新获取'
				setTimeout(this.timer, 1000)
			} else {
				this.textedTime = 0
				this.btntxt = '获取验证码'
				this.disabled = false
			}
		},
	},
}
</script>

<style lang="less">
#logon {
	min-width: 1200px;
	height: 100vh;
	background: #f9f9f9;
	.el-button {
		padding: 0 0;
	}
	.login-box {
		width: 1200px;
		height: 680px;
		min-width: 1200px;
		margin: 77px auto 0 auto;
		background: #fff;

		h1 {
			font-size: 36px;
			color: #70ccff;
			text-align: center;
			padding-top: 140px;
			font-weight: normal;
		}

		.login-input {
			width: 368px;
			margin: 108px auto 0 auto;
			.phone {
				margin-top: 30px;
			}
			input::-webkit-input-placeholder,
			textarea::-webkit-input-placeholder {
				color: #c1c1c1;
			}

			input:-moz-placeholder,
			textarea:-moz-placeholder {
				color: #c1c1c1;
			}

			input::-moz-placeholder,
			textarea::-moz-placeholder {
				color: #c1c1c1;
			}

			input:-ms-input-placeholder,
			textarea:-ms-input-placeholder {
				color: #c1c1c1;
			}
			.RegisterForm {
				.phone {
					.iconshouji {
						font-size: 24px;
					}
				}

				.email-box,
				.code,
				.phone-box,
				.paw-box {
					margin-bottom: 30px;
					div:first-of-type {
						width: 24px;
						height: 24px;
						display: flex;
						align-items: center;
					}
				}
				.email-box {
					.iconmail-copy {
						width: 24px;
						height: 24px;
					}
				}
				.code {
					position: relative;
					margin-bottom: 30px;
					.iconmail-copy1 {
						display: inline-block;
						width: 24px;
						height: 24px;
						font-size: 15px;
					}
					.send {
						width: 120px;
						height: 30px;
						background: #70ccff;
						font-size: 14px;
						color: #fffefe;
						text-align: center;
						position: relative;
						left: 190px;
						bottom: 8px;
						border: none;
						span {
							display: block;
							width: 120px;
							height: 30px;
							line-height: 30px;
						}
					}
					.ncolor {
						background: #d3d3d3;
					}
					.el-button--button {
						display: inline-block;
						width: 120px;
						height: 30px;
					}
				}

				.sign-in-btn {
					margin-top: 40px;
					width: 368px;
					height: 46px;
					font-size: 22px;
					line-height: 46px;
					border-radius: 6px;
					text-align: center;
					color: #fffefe;
					background: #70ccff;
					cursor: pointer;
					border: none;
				}
			}
			input {
				font-size: 16px;
				width: 338px;
				height: 24px;
				background: none;
				outline: none;
				border: none;
				border-bottom: 1px solid #d3d3d3;
				padding-left: 6px;
				margin-left: 5px;
				padding-right: 130px;
				box-sizing: border-box;
			}

			.iconfont {
				color: #5d5d5d;
				font-size: 24px;
			}

			.sendtxt .send-time {
				background: #d3d3d3;
			}
		}
	}
}
.copyright {
    margin-top: 400px;
	box-sizing: border-box;
	text-align: center;
	padding: 30px 0;
	font-size: 14px;
}
</style>
